import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})

export class AuthenticationService {

    authenticationState = new BehaviorSubject(false);

    keys: any;
    apis: any;

    constructor(
        private storage: Storage,
        private plt: Platform,
        private http: HttpClient
    ) {

        this.keys = environment.keys;
        this.apis = environment.apis;

        this.plt.ready().then(() => {
            this.checkToken();
        });
    }


    checkToken() {
        this.storage.get(this.keys.token).then(res => {
            if (res) {
                this.authenticationState.next(true);
            }
        });
    }

    login(email: string, password: string) {

        return this.http.post<any>(`${this.apis.bludot}/authenticate`, {
            'username': email + '-' + environment.app.uuid,
            'password': password,
            'rememberMe': true
        })
            .pipe(map(user => {
                // login successful if there's a jwt token in the response
                if (user && user.id_token) {
                    // store jwt token in storage
                    this.storage.set(this.keys.token, user.id_token).then(() => {
                        this.authenticationState.next(true);
                    });
                }
                return user;
            }));

    }


    register(firstName: string, lastName: string, phone: string, email: string, password: string) {

        return this.http.post<any>(`${this.apis.bludot}/ext-customer-user`, {
            'appUuid': environment.app.uuid,
            'firstName': firstName,
            'lastName': lastName,
            'phone': phone,
            'email': email,
            'password': password
        })
            .pipe(map(newUser => {
                return newUser;
            }));

    }



    logout() {
        return this.storage.remove(this.keys.token).then(() => {
            this.authenticationState.next(false);
        });
    }

    isAuthenticated() {
        return this.authenticationState.value;
    }

}

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    app: {
        name: 'Appetite',
        uuid: '22997627385326894554',
        defaultCurrency: 'GBP',
        mainLogo: 'assets/logo_alt@2x.png'
    },
    // app: {
    //     name: 'Lets Order',
    //     uuid: '70482590361963653114',
    //     defaultCurrency: 'EUR',
    //     mainLogo: 'assets/lets-order-app-trans.png'
    // },
    // links: {
    //     about: 'https://letsorder.ie/about-us/',
    //     policies: 'https://letsorder.ie/privacy-policy/'
    // },
    links: {
        about: 'https://www.bludottechnologies.com/about',
        policies: 'https://www.bludottechnologies.com/privacy-policy'
    },
    apis: {
        bludot: 'https://bludotapidev.quinns.pw/api',
        BludotApiKey: 'kmdTVmpK0v5uMKhM'
    },
    stripe: {
        publishableKey: 'pk_test_WupcZTroMgt74zi6V6SU6BaG', // test
        // publishableKey: 'pk_live_psDOKSORZBKnMtTXQ2TkbcTn' // live
    },
    keys: {
        activeStore: 'activestore_dev',
        checkout: 'checkout_dev',
        basket: 'basket_dev',
        token: 'access_token_dev'
    },
    urls: {
        images: 'https://bludot.ams3.digitaloceanspaces.com/dev/images'
    },
    orderTypes: [
        { name: 'Pre Pay For Collection', value: 'prepay', description: 'Pre Pay and collect in store', paymentRequired: true },
        { name: 'Pay In Store', value: 'instore', description: 'Pay & collect in store', paymentRequired: false },
        { name: 'Delivery Pre Pay', value: 'delivery', description: 'Pre Pay for delivery', paymentRequired: true },
        { name: 'Delivery Cash', value: 'delivery', description: 'Pay on delivery', paymentRequired: false },
        { name: 'Workplace Pre Pay', value: 'group', description: 'Pre Pay for workplace', paymentRequired: true }
    ]
};

/*
* For easier debugging in development mode, you can import the following file
* to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
*
* This import should be commented out in production mode because it will have a negative impact
* on performance if an error is thrown.
*/
  // import 'zone.js/dist/zone-error';  // Included with Angular CLI.

import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { SharedModule } from '../shared.module';

import { AuthenticationService } from '../_services/authentication.service';
import { ModalController } from '@ionic/angular';
import { LoginModalComponent } from '../_modals/login.modal/login.modal.component';


@Injectable({
    providedIn: 'root'
})

export class AuthGuard implements CanActivate {

    constructor(
        public auth: AuthenticationService,
        public modalController: ModalController
    ) {

    }


    async presentLoginModal() {
        const modal = await this.modalController.create({
            component: LoginModalComponent,
            componentProps: { value: 123 }
        });

        // modal.onDidDismiss().then((data) => {
        //     console.log('login modal dismissed');
        //     console.log(data);
        // });

        await modal.present();
    }


    canActivate(): boolean {
        if (this.auth.isAuthenticated()) {
            return true;
        } else {
            this.presentLoginModal();
            return false;
        }
    }

}

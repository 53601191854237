import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { Storage } from '@ionic/storage';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    keys: any;
    apis: any;

    constructor(
        private storage: Storage,
        private http: HttpClient
    ) {
        this.keys = environment.keys;
        this.apis = environment.apis;
    }

    currentUser() {

        return this.http.get<any>(`${this.apis.bludot}/ext-bludot-user/current`)
            .pipe(map(user => {
                return user;
            }));

    }

    setToken(token) {

        const body = {
            fcmRegistrationToken: token
        };

        return this.http.post<any>(`${this.apis.bludot}/ext-customer-user/set-fcm-registration-token`, body)
            .pipe(map(user => {
                return user;
            }));

    }


    getOrders(userId) {


        const size = 9999;
        const sort = 'id,desc';

        return this.http.get<any>(`${this.apis.bludot}/ext-purchase/by-customer-user/${userId}?size=${size}&sort=${sort}`)
            .pipe(map(orders => {
                return orders;
            }));

    }

    getSingleOrder(orderId) {


        return this.http.get<any>(`${this.apis.bludot}/ext-purchase/${orderId}`)
            .pipe(map(orders => {
                return orders;
            }));

    }


    getStripeCustomer(userId) {

        return this.http.get<any>(`${this.apis.bludot}/ext-stripe/customer/${userId}`)
            .pipe(map(user => {
                return user;
            }));

    }


    addStripeSource(userId, token) {

        const body = {
            customerUserId: userId,
            sourceToken: token
        };

        return this.http.post<any>(`${this.apis.bludot}/ext-stripe/add-source/`, body)
            .pipe(map(user => {
                return user;
            }));

    }



}

import { NgModule } from '@angular/core';
import { BrowserModule, HammerGestureConfig } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { LaunchNavigator } from '@ionic-native/launch-navigator/ngx';

import { Firebase } from '@ionic-native/firebase/ngx';

import { Observable } from 'rxjs';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HTTP_INTERCEPTORS, HttpClientModule, HttpRequest, HttpHandler, HttpInterceptor, HttpEvent } from '@angular/common/http';

import { Globals } from './_globals';

import { GoogleMaps, GoogleMap } from '@ionic-native/google-maps/ngx';
import { NativeStorage } from '@ionic-native/native-storage/ngx';
import { IonicStorageModule, Storage } from '@ionic/storage';
import { JwtModule, JWT_OPTIONS } from '@auth0/angular-jwt';
import { AppVersion } from '@ionic-native/app-version/ngx';

import { environment } from '../environments/environment';

import { ObjectFilterPipe } from './_pipes/object-filter.pipe';

import { IonicGestureConfig } from './hammer.provider';

import { LoginModalComponent } from './_modals/login.modal/login.modal.component';

import { OrderModule } from 'ngx-order-pipe';

export function jwtOptionsFactory(storage) {
    return {
        tokenGetter: () => {
            return storage.get(environment.keys.token);
        },
        whitelistedDomains: ['bludotapi.quinns.pw', 'bludotapidev.quinns.pw']
    };
}

export class AddHttpHeaderInterceptor implements HttpInterceptor {
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        let clonedReq = req;

        // console.log(req.url);
        if (req.url.includes('api/public')) {
            clonedReq = req.clone({
                headers: req.headers
                    .set('Bludot-Api-Key', environment.apis.BludotApiKey)
            });
        }
        return next.handle(clonedReq);

    }
}

@NgModule({
    imports: [
        BrowserModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        OrderModule,
        IonicStorageModule.forRoot({
            name: '_blu',
            driverOrder: ['indexeddb', 'sqlite', 'websql']
        }),
        JwtModule.forRoot({
            jwtOptionsProvider: {
                provide: JWT_OPTIONS,
                useFactory: jwtOptionsFactory,
                deps: [Storage],
            }
        })
    ],
    declarations: [
        AppComponent,
        LoginModalComponent,
        ObjectFilterPipe
    ],
    entryComponents: [
        LoginModalComponent
    ],
    providers: [
        StatusBar,
        SplashScreen,
        NativeStorage,
        LaunchNavigator,
        ObjectFilterPipe,
        GoogleMaps,
        AppVersion,
        Firebase,
        Globals,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        {
            provide: HammerGestureConfig,
            useClass: IonicGestureConfig
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AddHttpHeaderInterceptor,
            multi: true
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './_guards/auth.guard';


const routes: Routes = [
    { path: '', loadChildren: './tabs/tabs.module#TabsPageModule' },
    //  { path: 'storeinfo', loadChildren: './storeinfo/storeinfo.module#StoreinfoPageModule' },
    {
        path: 'checkout',
        canActivate: [AuthGuard],
        loadChildren: './checkout/checkout.module#CheckoutPageModule'
    }
];
@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }

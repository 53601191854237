import { Component } from '@angular/core';
import { first } from 'rxjs/operators';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Router } from '@angular/router';
import { Globals } from './_globals';
import { Firebase } from '@ionic-native/firebase/ngx';
import { UserService } from './_services/user.service';

import { AuthenticationService } from './_services/authentication.service';


@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html'
})
export class AppComponent {
    constructor(
        private platform: Platform,
        private splashScreen: SplashScreen,
        private statusBar: StatusBar,
        private authenticationService: AuthenticationService,
        private router: Router,
        private globals: Globals,
        private firebase: Firebase,
        private userService: UserService

    ) {
        this.initializeApp();
    }

    initializeApp() {
        this.platform.ready().then(() => {
            this.statusBar.styleLightContent();
            this.splashScreen.hide();

            this.authenticationService.authenticationState.subscribe(state => {
                if (state) {
                    console.log('App Component', 'State Logged In');


                    this.firebase.getToken()
                        .then((token) => {
                            if (!token) {
                                token = 'test_token';
                            }
                            console.log(`The token is ${token}`);

                            this.userService.setToken(token)
                                .pipe(first())
                                .subscribe(
                                    data => {
                                        console.log('fcm token response', data);

                                    },
                                    error => {
                                        console.log('error getting user', error);
                                    });


                        })
                        .catch(error => console.error('Error getting token', error));

                    this.globals.isAuthenticated = true;
                    // this.router.navigate(['members', 'dashboard']);
                } else {
                    console.log('App Component', 'State Logged Out');
                    this.globals.isAuthenticated = false;
                    // this.router.navigate(['login']);
                }
            });


        });
    }
}

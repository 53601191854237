import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({
    providedIn: 'root'
})
export class AlertService {

    constructor(
        public toastController: ToastController
    ) {

    }

    async presentToast(message, color = 'dark', options?) {

        const defaults = {
            message: message,
            showCloseButton: true,
            position: 'top',
            closeButtonText: 'Close',
            color: color,
            duration: 3000
        };

        const newOptions = Object.assign(defaults, options);

        const toast = await this.toastController.create(newOptions);
        toast.present();
    }



}

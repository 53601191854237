import { Injectable } from '@angular/core';

@Injectable()
export class Globals {

    isAuthenticated = false;

    currencies: any = [
        { 'name': 'Pound Sterling', 'value': 'GBP' },
        { 'name': 'United States Dollar', 'value': 'USD' },
        { 'name': 'Euro', 'value': 'EUR' }
    ];
    daysOfWeek: any = [
        { name: 'Sun', value: 0 },
        { name: 'Mon', value: 1 },
        { name: 'Tue', value: 2 },
        { name: 'Wed', value: 3 },
        { name: 'Thu', value: 4 },
        { name: 'Fri', value: 5 },
        { name: 'Sat', value: 6 }
    ];
}

import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AuthenticationService } from './../../_services/authentication.service';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Events } from '@ionic/angular';
import { first } from 'rxjs/operators';
import { Globals } from '../../_globals';
import { AlertService } from './../../_services/alert.service';

import { environment } from '../../../environments/environment';


@Component({
    selector: 'app-login-modal',
    templateUrl: './login.modal.component.html',
    styleUrls: ['./login.modal.component.scss']
})
export class LoginModalComponent implements OnInit {

    // "value" passed in componentProps
    @Input() value: number;

    loginForm: FormGroup;
    registerForm: FormGroup;
    viewType = 'login';
    app: any;

    constructor(
        private modalController: ModalController,
        private authService: AuthenticationService,
        public events: Events,
        public globals: Globals,
        public fb: FormBuilder,
        private alertService: AlertService
    ) {

        this.app = environment.app;

        this.authService.authenticationState.subscribe(state => {
            if (state) {
                this.events.publish('loginmodal:authenticated');
                this.closeModal();
            }
        });

        this.loginForm = fb.group({
            email: new FormControl('', Validators.compose([
                Validators.required,
                Validators.email
            ])),
            password: new FormControl('', Validators.compose([
                Validators.required,
                Validators.minLength(6)
            ]))
        });



        this.registerForm = fb.group({
            firstName: new FormControl('', Validators.compose([
                Validators.required,
                Validators.minLength(2)
            ])),
            lastName: new FormControl('', Validators.compose([
                Validators.required,
                Validators.minLength(2)
            ])),
            email: new FormControl('', Validators.compose([
                Validators.required,
                Validators.email
            ])),
            phone: new FormControl('', Validators.compose([
                Validators.required,
                Validators.minLength(7),
                Validators.maxLength(15)
            ])),
            password: new FormControl('', Validators.compose([
                Validators.required,
                Validators.minLength(6)
            ])),
            password2: new FormControl('', Validators.compose([
                Validators.required,
                Validators.minLength(6)
            ]))
        });


    }

    ngOnInit() {
        // console.log(this.value);
    }



    closeModal() {
        const data = {
            result: 'abc'
        };
        this.modalController.dismiss(data);
    }


    showLogin() {
        this.viewType = 'login';
    }

    showRegister() {
        this.viewType = 'register';
    }

    submitLogin() {
        const values = this.loginForm.value;
        this.authService.login(values.email, values.password)
            .pipe(first())
            .subscribe(
                data => {
                    this.alertService.presentToast('You have logged in', 'primary');
                },
                error => {
                    // console.log('login error', error);
                    this.alertService.presentToast(error.statusText, 'danger');
                });
    }



    submitRegister() {
        const values = this.registerForm.value;

        if (values.password !== values.password2) {
            this.alertService.presentToast('Passwords do not match', 'warning');
        } else {

            this.authService.register(values.firstName, values.lastName, values.phone, values.email, values.password)
                .pipe(first())
                .subscribe(
                    data => {
                        // console.log('register data', data);

                        if (data && data.id) {
                            console.log('lets login');
                            this.authService.login(values.email, values.password)
                                .pipe(first())
                                .subscribe(
                                    loginData => {
                                        this.alertService.presentToast('You have logged in', 'primary');
                                    },
                                    error => {
                                        // console.log('login error', error);
                                        this.alertService.presentToast(error.statusText, 'danger');
                                    });
                        }

                        this.alertService.presentToast('You are registered', 'primary');
                        this.closeModal();
                    },
                    error => {
                        let err = error.statusText;
                        if (error.error.title) {
                            err = error.error.title;
                        }
                        console.log('register error', error);
                        this.alertService.presentToast(err, 'danger');
                    });

        }
    }





}
